import React from 'react'
import AppRouter from './routes/routes'

const App = () => {
  return (
    <AppRouter />
  )
}

export default App
