import styled, { keyframes } from 'styled-components';

const flicker = keyframes`
  0%, 18%, 22%, 25%, 53%, 57%, 100% {
    opacity: 1;
  }
  20%, 24%, 55% {
    opacity: 0;
  }
`;

const staticNoise = keyframes`
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 100%;
  }
`;

const pulse = keyframes`
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 1050;
  }
`;

const BgContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  background-image: 
    linear-gradient(to right, rgba(255, 255, 255, 0.2) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(255, 255, 255, 0.2) 1px, transparent 1px);
  background-size: 20px 20px; /* Adjust the size to fit your needs */
  animation: ${staticNoise} 3s steps(10), ${flicker} 3s;
  overflow: hidden;
  z-index: 1;
`;

const StyledSVG = styled.svg`
  height: 100%;
  width: 100%;
`;

const StyledPath = styled.path`
  stroke: var(--pink-color);
  stroke-width: 1px;
  fill: none;
  stroke-dasharray: 150, 200;
  stroke-dashoffset: 0;
  animation: ${pulse} 8s infinite linear;
`;

const Overlay = styled.div`
  position: absolute;
  background: radial-gradient(ellipse at center, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0.8) 100%);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const HomeBackground = ({ children }) => (
  <BgContainer>
    <StyledSVG x="0px" y="0px" viewBox="0 0 298 53.9">
      <StyledPath className="st0" d="M297.5,41.2h-76.6c-0.5,0-0.9,0.4-1,0.8l-1.6,11.3l-3.1-32c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.3-1,0.8
                       l-5.3,25.5l-2.3-10.9c-0.1-0.4-0.4-0.7-0.9-0.8c-0.4,0-0.8,0.2-1,0.6l-2.3,4.8h-107c0,0,0,0,0,0H82c-1.6,0-2.2,1.1-2.2,1.6
                       l-1.6,11.3l-3.1-52c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.3-1,0.8l-9.3,45.5l-2.3-10.9c-0.1-0.4-0.4-0.7-0.9-0.8c-0.4,0-0.8,0.2-1,0.6
                       l-2.3,4.8H0.5"/>
    </StyledSVG>
    <Overlay>
      {children}
    </Overlay>
  </BgContainer>
);

export default HomeBackground;
