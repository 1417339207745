import React from 'react'
import styled from 'styled-components'
import rosebutton from '../../assets/buttons/rose-button.svg';
import { Link } from 'react-router-dom';

const ButtonHeader = styled.h5`
    font-size: 3rem;
    color: white;
    text-align: left;
    font-weight: 100;
    margin: 0;
    line-height: 0.7;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);

    @media (max-width: 1200px) {
        font-size: 2.5rem;
    }

    @media (max-width: 1000px) {
        font-size: 2rem;
    }
    
    @media (max-width: 850px) {
        font-size: 1.8rem;
    }

    @media (max-width: 600px) {
        font-size: 1.5rem;
    }

    @media (max-width: 400px) {
        font-size: 1.3rem;
    }
`;

const ButtonSubHeader = styled.h6`
    font-size: 1.5rem;
    color: white;
    text-align: left;
    font-weight: 100;
    margin: 0;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);

    @media (max-width: 1200px) {
        font-size: 1.2rem;
    }

    @media (max-width: 1000px) {
        font-size: 1rem;
    }

    @media (max-width: 850px) {
        font-size: 0.8rem;
    }

    @media (max-width: 600px) {
        font-size: 0.6rem;
    }

`;

const ButtonImage = styled.img`
    position: absolute;
    width: 40px;
    height: 40px;
    right: 10px;
    top: 10px;
    transition: all 0.3s ease-in-out;
    transform: rotate(45deg);
`;

const CardButtonContainer = styled(Link)`
    display: flex;
    flex: 1;
    position: relative;
    height: auto;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    cursor: pointer;
    border-radius: 10px;
    max-width: 300px;
    padding: 5px;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    background: linear-gradient(45deg , rgba(255, 45, 85, 0.5), rgba(255, 45, 85, 0));
    text-decoration: none;

    &:hover {
        ${ButtonImage} {
            width: 100px;
            height: 100%;
            transform: rotate(45deg);
            opacity: 0.5;
        }
    }

    @media (max-width: 1200px) {
        min-width: 250px;
        max-width: 250px;
    }

    @media (max-width: 1000px) {
        min-width: 0px;
        width: auto;
        min-height: 100px
    }

    @media (max-width: 850px) {
        min-width: 0px;
        width: auto;
        min-height: 80px
    }

    @media (max-width: 600px) {
        min-width: 0px;
        width: auto;
        min-height: 60px
    }
`;

const CardButton = ({ header, subHeader, href, toggleMenu }) => {
    const handleNavLinkClick = (hash) => {
        scrollToHash(hash); // Scroll to the section
    };

    // Reusable scroll function
    const scrollToHash = (hash) => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return (
        <CardButtonContainer to={href} onClick={
            () => {
                toggleMenu();
                handleNavLinkClick(href);
            }
        }>
            <ButtonHeader>
                {header}
            </ButtonHeader>
            <ButtonSubHeader>
                {subHeader}
            </ButtonSubHeader>
            <ButtonImage src={rosebutton} />
        </CardButtonContainer>
    )
}

export default CardButton
