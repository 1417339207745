import React, { useState } from 'react'
import styled, { keyframes } from 'styled-components'
import NavThorns from '../../assets/navigation/navigation-thorns.svg';

// Keyframes for fade-in and fade-out animations
const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const fadeOut = keyframes`
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
`;

const scaleIn = keyframes`
    from {
        opacity: 0;
        scale: 0.9;
    }
    to {
        opacity: 1;
        scale: 1;
    }
`;

const scaleOut = keyframes`
    from {
        opacity: 1;
        scale: 1;
    }
    to {
        opacity: 0;
        scale: 0.9;
    }
`;


const BackgroundOverlay = styled.div`
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 200;
    justify-content: center;
    align-items: center;
    // animation: ${props => props.$isOpen ? fadeIn : fadeOut} 0.5s ease-in-out;
`;

const NewsModalContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 300px;
    height: auto;
    backdrop-filter: blur(10px);
    border-radius: 15px;
    background-color: rgba(0, 0, 0, 0.7);
    box-shadow: 0 0 10px rgba(255, 45, 85, 1);
    border: 1px solid var(--pink-color);
    padding: 20px;
    z-index: 200;
    animation: ${props => props.$isOpen ? scaleIn : scaleOut} 0.5s ease-in-out;

    @media (max-width: 600px) {
        width: 200px;
    }
`;

const NewsModalHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const CrossThorns = styled.img`
    position: absolute;
    width: 100%;
    left: 0px;
    height: auto;
    object-fit: cover;
    z-index: 200;
    transition: all 0.3s ease-in-out;
    transform: rotate(${({ $left }) => ($left ? '225deg' : '-45deg')});
`;

const CrossContainer = styled.div`
    display: flex;
    position: relative;
    width: 45px;
    height: 45px;
    justify-content: center;
    align-items: center;
    z-index: 200;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border-radius: 50%;
    box-shadow: ${({ $isOpen }) => ($isOpen ? '0 0 10px 5px rgba(255, 45, 85, 0.5)' : 'none')};

    &:hover {
        box-shadow: ${({ $isOpen }) => ($isOpen ? '0 0 10px 5px rgba(255, 255, 255, 0.5)' : '0 0 10px 5px rgba(255, 45, 85, 0.5)')};
        transform: ${({ $isOpen }) => ($isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
    }

    @media (max-width: 600px) {
        top: 0px;
        left: 0px;
    }
`;

const NewsModalContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    margin: 20px 0 0 0;
    width: 100%;
    height: 100%;
`;

const NewsModalContentSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: auto;

    h4 {
        color: var(--pink-color);
        font-size: 1.4rem;
        margin: 0;
    }

    a {
        color: var(--pink-color);
        text-decoration: underline;
        font-weight: bold;
    }
`;

const NewsButton = styled.div`
    display: flex;
    background-color: var(--pink-color);
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 5px 10px;
    color: white;
    border: 2px solid var(--pink-color);
    transition: all 0.3s ease-in-out;
    width: 90%;
    margin: 20px auto 0 auto;

    &:hover {
        cursor: pointer;
        box-shadow: 0 0 7px rgba(255, 45, 85, 1);
        border: 2px solid white;
    }

    &:active {
        transform: scale(0.9);
    }
`;

const NewsModal = ({
    isOpen,
    setIsOpen,
    newsHeading,
    newsMessage,
    link,
    buttonText
}) => {
    const [isOpenAnimation, setIsOpenAnimation] = useState(true);

    // wait 0.5s for the animation to finish before closing the modal
    const closeModal = () => {
        setIsOpenAnimation(false);
        setTimeout(() => {
            setIsOpen(false);
        }, 500);
    }

    return (
        <BackgroundOverlay $isOpen={isOpen}>
            <NewsModalContainer $isOpen={isOpenAnimation}>
                <NewsModalHeader>
                    <h3>{newsHeading}</h3>
                    <CrossContainer $isOpen={isOpen} onClick={closeModal}>
                        <CrossThorns src={NavThorns} alt="cross" $left={true} />
                        <CrossThorns src={NavThorns} alt="cross" />
                    </CrossContainer>
                </NewsModalHeader>
                <NewsModalContent>
                    <NewsModalContentSection>
                        <p>
                            {newsMessage}
                        </p>
                    </NewsModalContentSection>
                </NewsModalContent>
                <NewsButton onClick={() => window.open(link, '_blank')}>
                    {buttonText}
                </NewsButton>
            </NewsModalContainer>
        </BackgroundOverlay>
    )
}

export default NewsModal
