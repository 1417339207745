import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NavigationBar from '../components/navigation/NavigationBar';
import Footer from '../components/footer/Footer';

import Home from '../pages/Home';

const AppRouter = () => {

    return (
        <Router>
            <Routes>
                <Route element={<NavigationBar />}>
                    <Route element={<Footer />}>
                        <Route path="/" element={<Home />} />
                    </Route>
                </Route>
            </Routes>
        </Router>
    )
};

export default AppRouter;