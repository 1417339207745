import React from 'react'
import styled from 'styled-components'
import NavThorns from '../../assets/navigation/navigation-rose.svg';

const PopOutText = styled.h6`
    font-size: 1.6rem;
    color: white;
    font-weight: 100;
    margin: 0;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
        color: var(--primary-color);
    }
`;

const PopOutImage = styled.img`
    width: 30px;
    height: auto;
    transform: rotate(-45deg);
    transition: all 0.5s ease-in-out;
    cursor: pointer;
`;

const Container = styled.div`
    display: flex;
    position: fixed;
    top: 20%;
    right: 0;
    width: auto;
    height: auto;
    padding: 10px 20px 10px 10px;
    background-color: var(--pink-color);
    z-index: 100;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease-in-out;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

    &:hover {
        background-color: var(--pink-color);

        ${PopOutImage} {
            transform: rotate(315deg);
        }
    }
`;


const PopOut = ({
    onClick,
    popOutText
}) => {
    return (
        <Container onClick={onClick}>
            <PopOutImage src={NavThorns} />
            <PopOutText>
                {popOutText}
            </PopOutText>
        </Container>
    )
}

export default PopOut
