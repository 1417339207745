import React from 'react'
import styled from 'styled-components'
import thornborder from '../../assets/cards/rose-card-border.svg'

const RoseCard = styled.div`
    position: relative;
    width: 300px;
    height: auto;
    padding: 80px 0 0 0;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 1200px) {
        width: 250px;
    }

    @media (max-width: 850px) {
        width: 180px;
        padding: 60px 0 0 0;
    }

    @media (max-width: 500px) {
        width: 150px;
    }
`;

const CardContent = styled.div`
    position: relative;
    width: 100%;
    height: 70%;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: ${({ align }) => align === 'right' ? 'flex-end' : 'flex-start'};
    gap: 10px;
    z-index: 2;

    h3 {
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    }

    p {
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    }
`;

const ThornBorderTop = styled.img`
    position: absolute;
    top: -10%;
    ${({ align }) => align === 'right' ? 'right: -5%;' : 'left: -5%;'}
    width: 90%;
    height: auto;
    z-index: 1;

    @media (max-width: 1200px) {
        top: -5%;
    }

    @media (max-width: 850px) {
        top: 0%;
    }
`;

// const ThornBorderBottom = styled.img`
//     position: absolute;
//     bottom: -10%;
//     left: 5%;
//     width: 90%;
//     height: auto;
//     z-index: 1;
// `;

// const ThornBorderLeft = styled.img`
//     position: absolute;
//     left: -20%;
//     top: 15%;
//     width: auto;
//     height: 100px;
//     z-index: 1;
//     transform: rotate(90deg);
// `;

// const ThornBorderRight = styled.img`
//     position: absolute;
//     right: -23%;
//     bottom: 15%;
//     width: auto;
//     height: 100px;
//     z-index: 1;
//     transform: rotate(90deg);
// `;

const RoseCards = (
    { children, align }
) => {
    return (
        <RoseCard>
            <ThornBorderTop src={thornborder} alt="thorn border" align={align} />
            {/* <ThornBorderLeft src={thornborder} alt="thorn border" />
            <ThornBorderRight src={thornborder} alt="thorn border" /> */}
            {/* <ThornBorderBottom src={thornborder} alt="thorn border" /> */}
            <CardContent align={align}>
                {children}
            </CardContent>
        </RoseCard>
    )
}

export default RoseCards
