import styled from 'styled-components'

const SectionContainer = styled.div`
    width: 80%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    z-index: 2;

    .left {
        align-items: flex-start;
    }

    .right {
        align-items: flex-end;
    }

    @media (max-width: 1200px) {
        width: 90%;
        gap: 40px;
    }

    @media (max-width: 850px) {
        gap: 20px;
    } 

    @media (max-width: 750px) {
        height: 100%;
        max-height: 700px;
    }
`;

const SectionSubContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 80%;

    @media (max-width: 1200px) {
        width: 90%;
    }

    @media (max-width: 750px) {
        width: 100%;
        min-width: 400px;
    }

    @media (max-width: 600px) {
        min-width: 280px;
    }

    .left {
        align-items: flex-start;
    }

    .right {
        align-items: flex-end;
    }
`;



export { SectionContainer, SectionSubContainer }