import React from 'react'
import styled from 'styled-components'
import { Outlet } from 'react-router-dom';

const Body = styled.div`
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
`;

const FooterContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    position: relative;
    width: 100vw;
    height: auto;
    max-height: 200px;
    background-color: rgba(0, 0, 0, 0.9);
    background-image: 
        linear-gradient(to right, rgba(255, 255, 255, 0.2) 1px, transparent 1px),
        linear-gradient(to bottom, rgba(255, 255, 255, 0.2) 1px, transparent 1px);
    background-size: 20px 20px; /* Adjust the size to fit your needs */
    z-index: 1;
`;

const FooterInnerContainer = styled.div`
    display: flex;
    position: relative;
    margin: 0 10%;
    width: 80%;
    height: auto;
    padding: 20px 0;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 850px) {
        margin: 0 5%;
        width: 90%;
    }

    @media (max-width: 500px) {
        padding: 10px 0;
    na}
    
    p {
        color: white;
        font-size: 1.4rem;
        font-weight: 100;
        margin: 0;

        &:hover {
            cursor: pointer;
        }

        @media (max-width: 850px) {
            font-size: 1.2rem;
        }

        @media (max-width: 750px) {
            font-size: 1rem;
        }

        @media (max-width: 500px) {
            font-size: 0.8rem;
        }
    }
`;

const Group = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: auto;
    align-items: center;
`;

const Footer = () => {
    return (
        <Body>
            <Outlet />
            <FooterContainer>
                <FooterInnerContainer>
                    <p>&copy; 2024 ByteLuv & Co.</p>
                    <Group>
                        <p>Privacy Policy</p>
                        <p>Terms of Service</p>
                    </Group>
                </FooterInnerContainer>
            </FooterContainer>
        </Body>
    )
}

export default Footer
