import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import Rose1Svg from '../../assets/background-images/rose-background-1.svg';
import Rose2Svg from '../../assets/background-images/rose-background-2.svg';
import Rose4Svg from '../../assets/background-images/rose-background-4.svg';

const floatUp = keyframes`
  0% {
    top: 50%;
    opacity: 0;
  }
  100% {
    top: -3%;
    opacity: 1;
  }
`;

const BgContainer = styled.div`
    display: flex;
    position: relative;
    height: auto;
    min-height: 100vh;
    width: 100vw;
    margin-top: 100vh;
    background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.9) 100%);
    z-index: 1;

    @media (max-width: 800px) {
        min-height: 800px;
    }
`;

const Rose2 = styled.img`
    position: absolute;
    top: ${({ $scrollPosition, $maxScroll }) => `-${500 - Math.min($scrollPosition, $maxScroll) * 0.1}px`};
    left: -250px;
    height: 700px;
    width: auto;
    padding: 0;
    margin: 0;
    z-index: 1;
    pointer-events: none;
    opacity: ${({ $scrollPosition, $maxScroll }) => Math.max(0.9 - Math.min($scrollPosition, $maxScroll) * 0.005, 0.4)};
    transform: ${({ $scrollPosition, $maxScroll }) => `rotate(${30 + Math.min($scrollPosition, $maxScroll) * 0.15}deg) scale(${1 + Math.min($scrollPosition, $maxScroll) * 0.002})`};
    transition: top 0.3s ease, opacity 0.3s ease, transform 0.3s ease;

    @media (max-width: 1200px) {
      left: -200px;
      top: ${({ $scrollPosition, $maxScroll }) => `-${50 - Math.min($scrollPosition, $maxScroll) * 0.01}vh`};
      transform: ${({ $scrollPosition, $maxScroll }) => `rotate(${30 + Math.min($scrollPosition, $maxScroll) * 0.15}deg) scale(${1 + Math.min($scrollPosition, $maxScroll) * 0.0015})`};
      height: 600px;
      transition: top 0.4s ease, opacity 0.4s ease, transform 0.4s ease;
    }

     @media (max-width: 1050px) {
        left: -220px;
      }

    @media (max-width: 900px) {
      left: -240px;
      height: 500px;
    }

    @media (max-width: 850px) {
      top: ${({ $scrollPosition, $maxScroll }) => `-${40 - Math.min($scrollPosition, $maxScroll) * 0.05}%`};
      transform: ${({ $scrollPosition, $maxScroll }) => `rotate(${30 + Math.min($scrollPosition, $maxScroll) * 0.15}deg) scale(${1 + Math.min($scrollPosition, $maxScroll) * 0.001})`};
      height: 500px;
    }

    @media (max-width: 750px) {
      height: 400px;
      transform: ${({ $scrollPosition, $maxScroll }) => `rotate(${20 + Math.min($scrollPosition, $maxScroll) * 0.15}deg) scale(${1 + Math.min($scrollPosition, $maxScroll) * 0.001})`};
      left: -180px;
    }

    @media (max-width: 650px) {
      height: 360px;
      left: -160px;
    }

    @media (max-width: 400px) {
      height: 300px;
      left: -120px;
    }
`;

const Rose1 = styled.img`
  position: absolute;
  top: -350px;
  right: 0;
  transform: rotate(-90deg);
  padding: 0;
  margin: 0;
  width: 450px;
  z-index: 2;
  pointer-events: none;
  transition: top 0.1s;

  @media (max-width: 1200px) {
    top: -230px;
    width: 300px;
  }

  @media (max-width: 1050px) {
    top: -230px;
    width: 270px;
  }

  @media (max-width: 850px) {
    top: -200px;
    width: 250px;
  }

  @media (max-width: 750px) {
    top: -150px;
    width: 200px;
  }
`;

const RosesBg = styled.div`
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 100vh;
    background-size: 20px 20px;
    z-index: 1;
    animation: ${floatUp} 1s ease-out forwards;
`;

const Rose4 = styled.img`
    position: absolute;
    top: -200px;
    right: 25%;
    transform: translateX(50%);
    padding: 0;
    margin: 0;
    width: 50%;
    z-index: 2;
    pointer-events: none;
    transition: top 0.1s;

    @media (max-width: 1200px) {
      top: -150px;
    }

    @media (max-width: 1050px) {
      top: -130px;
    }

    @media (max-width: 850px) {
      top: -100px;
    }

    @media (max-width: 750px) {
      top: -70px;
    }
`;

const Rose5 = styled.img`
    position: absolute;
    top: -200px;
    left: 25%;
    transform: translateX(-50%);
    padding: 0;
    margin: 0;
    width: 50%;
    z-index: 2;
    pointer-events: none;
    transition: top 0.1s;

    @media (max-width: 1200px) {
      top: -150px;
    }

    @media (max-width: 1050px) {
      top: -130px;
    }

    @media (max-width: 850px) {
      top: -100px;
    }

    @media (max-width: 750px) {
      top: -70px;
    }
`;

const RosesBackground = ({ children }) => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const maxScroll = 650; // Define the maximum scroll position for the animation

  return (
    <BgContainer>
      <RosesBg>
        <Rose2 src={Rose2Svg} alt="rose background" $scrollPosition={scrollPosition} $maxScroll={maxScroll} />
        <Rose1 src={Rose1Svg} alt="rose background" />
      </RosesBg>
      <Rose4 src={Rose4Svg} alt="rose background" />
      <Rose5 src={Rose4Svg} alt="rose background" />
      {children}
    </BgContainer>
  );
};

export default RosesBackground;
